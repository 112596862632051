
import {computed, defineComponent, ref} from "vue";
import ReservationFlightForm from "@/components/reservations/parts/ReservationFlightForm.vue";

export default defineComponent({
    name: "update-reservation-flight-modal",
    components: {
        ReservationFlightForm,
    },
    methods: {
        setContent(prmFlightInfo){
            console.log("setContent Update "+JSON.stringify(prmFlightInfo));
            this.refReservationFlightForm.setContent(prmFlightInfo);
        },
    },
    props: {
        reservationid: {type:Number, required:true},
    },
    setup(props) {
        const refReservationFlightForm = ref(null);

        return {
            refReservationFlightForm,
        };
    },

});
