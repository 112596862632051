
import {defineComponent, onMounted, ref} from "vue";
import PaxTransferDetail from "@/components/new-reservation-manuel/transfer/PaxTransferDetail.vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
    name: "reservation-transfer-update-modal",
    components: {
        PaxTransferDetail,
    },
    methods: {
        setContent(prmTransferInfo) {
            console.log("Res setContent "+JSON.stringify(prmTransferInfo));
            (this.refComp_PaxTransferDetail as any).setContent(prmTransferInfo);
        },
        getRezervation(prmReservation){
          this.getRezervationInner(prmReservation);
        }
    },
    props: {
      reservationid: {type:Number, required:true},
    },
    setup(props) {



        let refComp_PaxTransferDetail = ref(null);
        const arrReservationInfo = ref([]);

        function click_Update() {
            processSend();
        }


        function getRezervationInner(prmReservation) {

          (arrReservationInfo.value).push(prmReservation);
        }

        function processSend() {
            let rv = {isok : true, result: {paxtransferdetail: {} }};

            if (rv.isok){

                if (rv.isok) {
                    let resA  = (refComp_PaxTransferDetail.value as any).getResults();
                    if (resA.isok) {
                        rv.result.paxtransferdetail = resA.result;
                    } else {
                        rv.isok = false;
                    }
                }

            }

            //SUCCESS
            if (rv.isok) {
                console.log("processSend SUCCESS DONE "+JSON.stringify(rv));

                const vvPayload = {
                    ProductType: "TRANSFER",
                    ReservationID: props.reservationid,
                    ID: (rv.result.paxtransferdetail as any).reservationProductId,
                    TotalCost: (rv.result.paxtransferdetail as any).transferCost,
                    TotalCurCost: (rv.result.paxtransferdetail as any).transferCost,
                    StartDate: (rv.result.paxtransferdetail as any).transferDate,
                    TotalPrice: (rv.result.paxtransferdetail as any).transferPayment,
                    TotalAccountPrice: (rv.result.paxtransferdetail as any).transferPayment,
                    CHDCount: (rv.result.paxtransferdetail as any).trsCHDCount,
                    ADTCount: (rv.result.paxtransferdetail as any).trsADTCount,


                    RemarksJSON1: JSON.stringify((rv.result.paxtransferdetail as any).RemarksJSON1),
                    RemarksJSON3: JSON.stringify((rv.result.paxtransferdetail as any).RemarksJSON3),
                };

                store.dispatch(Actions.RESERVATIONS_PRODUCT_UPDATE, vvPayload).then((prmData) => {
                  if((arrReservationInfo.value[0] as any).ITEMS[0].ProductType==="TRANSFER"){
                    if((rv.result.paxtransferdetail as any).transferType==="ARRIVAL"){
                      store.dispatch(Actions.RESERVATIONS_EDIT, {ID: (arrReservationInfo.value[0] as any).ID, StartDate:(rv.result.paxtransferdetail as any).transferDate});

                    }else{
                      store.dispatch(Actions.RESERVATIONS_EDIT, {ID: (arrReservationInfo.value[0] as any).ID, FinishDate:(rv.result.paxtransferdetail as any).transferDate});

                    }
                  }
                    if (prmData.status===200) {
                        Swal.fire('Güncellendi!', 'Kayit günceleme işlemi tamamlandı.', 'success').then((result)=>{
                            location.reload();

                        });
                    } else {
                        libTools.method.swalError("Tamam", "Hata Oluştu");
                    }
                }).catch(() => {
                    libTools.method.swalError("Tamam", "Hata Oluştu");
                });

            }
        }

        return {
            refComp_PaxTransferDetail,
            click_Update,
            getRezervationInner,
        };
    },

});
