
import {defineComponent, onMounted, ref} from "vue";
import {libTools} from "@/core/appcore/libs/Lib_Tools";


export default defineComponent({
    name: "room-count",
    props: {
    },

    methods: {
        getResults() {
            let rv = this.confirmValues();
            return rv;
        },
    },
    setup(props, { emit }) {
        let ccvRoomCount = ref(1);

        onMounted(()=>{
            emit_OnRoomCountChanged();
        });

        function change_RoomCount() {
            emit_OnRoomCountChanged();
        }

        function emit_OnRoomCountChanged() {
            emit("on-roomcount-changed", ccvRoomCount.value);
        }

        //##Confirm
        function confirmValues() {
            let rv = {isok: true, result: {},};

            if (rv.isok) {
                rv.result= {
                    RoomCount: Number(libTools.method.getElValue("sel_HotelReservationAdd_RoomCount")),
                };
            }

            return rv;
        }

        return {
            ccvRoomCount,
            change_RoomCount,
            confirmValues,
        };
    },
});
