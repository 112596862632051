

import {computed, defineComponent, ref} from "vue";
import {Field, Form} from "vee-validate";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";


export default defineComponent({
    name: "reservation-detail-contact-modal",
    components: {
      Form,
      Field,
    },

    props: {
      reservationid: {type:Number, required:true},
    },

    setup(props) {
      const submitButtonRef = ref<null | HTMLButtonElement>(null);
      const newModalRef = ref<null | HTMLElement>(null);

      const myDetails = computed(() => {
        const dataComputed = store.getters.reservationsDetails;
        if(dataComputed!==undefined && dataComputed.Contact!==undefined){
          setContact(dataComputed.Contact);
        }
        return dataComputed;
      })

      function setContact(prmData){
        objContact.value= JSON.parse(prmData);
      }

      const objContact = ref({});

      const submit = () => {
        if (!submitButtonRef.value) {
          return;
        }

        const payload1 = {
          ID: props.reservationid,
          Contact: JSON.stringify(objContact.value),
        }

        store.dispatch(Actions.RESERVATIONS_EDIT, payload1)
            .then(() => {
              store.dispatch(Actions.RESERVATIONS_ALL_DETAILS, {ID: props.reservationid,});
              Swal.fire({
                text: "Tebrikler. Başarılı bir şekilde işlemi tamamladınız.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Geri Dön!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                submitButtonRef.value.disabled = false;
                submitButtonRef.value.setAttribute("data-kt-indicator", "off");
                hideModal(newModalRef.value);
                // Go to page after successfully login
                //router.push({name: "userlist"});
              });
            }).catch(() => {
          Swal.fire({
            text: store.getters.getReservationsErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Tekrar dene!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
          submitButtonRef.value.disabled = false;
          submitButtonRef.value.setAttribute("data-kt-indicator", "off");
        });
      }


        return {
          submitButtonRef,
          newModalRef,
          submit,
          myDetails,
          objContact,
        };
    },
});

