
import {computed, defineComponent, onMounted, ref} from "vue";
import ReservationDetailsUpdate from "@/components/reservations/ReservationDetailsUpdate.vue";
import ReservationDetailsUpdateModal  from "@/components/payment-methods/bank-accounts/ReservationDetailsUpdateModal.vue";
import ReservationDetailsContactModal from "@/components/general-modals/ReservationDetailsContactModal.vue";
import NewReservationFlight from "@/components/reservations/NewReservationFlight.vue";
import UpdateReservationFlight from "@/components/reservations/UpdateReservationFlight.vue";
import ReservationHotelAddModel from "@/components/general-modals/ReservationHotelAddModel.vue";
import ReservationHotelUpdateModal from "@/components/general-modals/ReservationHotelUpdateModal.vue";
import NewReservationModal from "@/components/general-modals/NewReservationModal.vue";
import ReservationTransferAddModal from "@/components/general-modals/ReservationTransferAddModal.vue";
import ReservationTransferUpdateModal from "@/components/general-modals/ReservationTransferUpdateModal.vue";
import ReservationPaymentAddModal from "@/components/general-modals/ReservationPaymentAddModal.vue";
import HotelVoucherModal from "@/components/general-modals/vouchers/HotelVoucherModal2.vue";
import FlightVoucherModal from "@/components/general-modals/vouchers/FlightVoucherModal2.vue";
import ReservationVoucherModal from "@/components/general-modals/vouchers/ReservationVoucherModal.vue";
import TransferVoucherModal from "@/components/general-modals/vouchers/TransferVoucherModal.vue";
import KPlusDetailModal from "@/components/general-modals/KPlusDetailModal.vue";
import Remarks from "@/components/general-modals/cards/Remarks.vue";
import {useRoute} from "vue-router";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import { dateConvert } from "@/core/filters/datatime";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import MasterConfig from "@/core/config/MasterConfig";
import Swal from "sweetalert2/dist/sweetalert2.js";


export default defineComponent({
  name: "Reservation-Details",
  components: {
    ReservationDetailsUpdate,
    ReservationDetailsUpdateModal,
    ReservationDetailsContactModal,
    NewReservationFlight,
    UpdateReservationFlight,
    ReservationHotelAddModel,
    ReservationHotelUpdateModal,
    NewReservationModal,
    ReservationTransferAddModal,
    ReservationTransferUpdateModal,
    HotelVoucherModal,
    FlightVoucherModal,
    ReservationVoucherModal,
    TransferVoucherModal,
    ReservationPaymentAddModal,
      KPlusDetailModal,
    Remarks,
  },
  setup() {

    onMounted(()=>{
      console.log("Reservation Details Page is Opened");


    });


    var theDetail = ref({});
    const arrPaxes = ref([]);

    const refHotelVoucherModal = ref(null);
    const refFlightVoucherModal = ref(null);
    const refHotelAddModal = ref(null);
    const refHotelUpdateModal = ref(null);
    const refFlightUpdateModal = ref(null);
    const refTransferUpdateModal = ref(null);
    const refTransferVoucherModal = ref(null);
    const refReservationVoucherModal = ref(null);
    const refPaymentAddModal = ref(null);
      const refKPlusDetailModal = ref(null);

    const refRemarks = ref(null);

    const route = useRoute();
    const paramId = ref(route.params.id);

    const id = ref(route.params.id);
    const payload = {
      ID: id
    }

    store.dispatch(Actions.RESERVATIONS_ALL_DETAILS, {ID: paramId.value,});
    const dataReservationsDetails = computed(() => {
      let dataComputed = store.getters.reservationsDetails;
      setDetail(dataComputed);
      return dataComputed;
    });



    function setDetail(prmDetail) {
      theDetail.value = prmDetail;
      arrPaxes.value = libTools.method.getPaxes(theDetail.value, "FLIGHT");
      if (arrPaxes.value.length===0) {
        let tmpPaxes = libTools.method.getPaxes(theDetail.value, "HOTEL");
        if (tmpPaxes.length>0) {
          arrPaxes.value = libTools.method.convertPaxesToGeneral( tmpPaxes[0] );
        }
      }
      //console.log("ReservationDetail setDetail theDetail: "+JSON.stringify(theDetail.value));
      //console.log("ReservationDetail setDetail arrPaxes: "+JSON.stringify(arrPaxes.value));
      //console.log("ReservationDetail setDetail PAYMENT: "+JSON.stringify((theDetail.value as any).PAYMENT));

      if (refRemarks.value!==null) {
        (refHotelUpdateModal.value as any).initContent(theDetail);
        (refRemarks.value as any).initComp(theDetail);
      }
    }

    function getItems(prmReservationDetail, prmProductType) {
      let rv = [];
      if (prmReservationDetail.ITEMS!==undefined) {
        for (let i=0; i<prmReservationDetail.ITEMS.length; i++) {
          let each = prmReservationDetail.ITEMS[i];
          if (each.ProductType===prmProductType) {
            rv.push(each);
          }
        }
      }
      return rv;
    }

    function getFirstPax(prmPaxes) {
      if (prmPaxes!==undefined && prmPaxes!==null) {
        if (prmPaxes.length>0) {
          return prmPaxes[0];
        }
      }
      return {
        PaxName: "",
        PaxSurname: "",
      }
    }

    function click_Open_VoucherReservation() {
      (refReservationVoucherModal.value as any).setValues(theDetail);
    }

    function click_Open_VoucherHotel(prmHotel) {
      console.log("click_Open_VoucherHotel arrPaxes: "+JSON.stringify(arrPaxes.value));

      (refHotelVoucherModal.value as any).setHotel(theDetail.value, prmHotel, arrPaxes.value);
    }

    function click_Open_VoucherFlight(prmFlight) {
      (refFlightVoucherModal.value as any).setFlight(theDetail, prmFlight, arrPaxes.value);
    }

    function click_Open_VoucherTransfer(prmTransfer) {
      (refTransferVoucherModal.value as any).setTransfer(theDetail, prmTransfer);

    }

    function click_Open_HotelAdd() {
      (refHotelAddModal.value as any).setContent(theDetail);
    }

    function click_Open_HotelUpdate(prmHotelInfo) {
      (refHotelUpdateModal.value as any).setContent(prmHotelInfo);
      (refHotelUpdateModal.value as any).getRezervation(theDetail.value);
    }

    function click_Open_FlightUpdate(prmFlightInfo) {
      (refFlightUpdateModal.value as any).setContent(prmFlightInfo);
    }

    function click_Open_TransferUpdate(prmTransferInfo) {
      (refTransferUpdateModal.value as any).setContent(prmTransferInfo);
      (refTransferUpdateModal.value as any).getRezervation(theDetail.value);
    }

    function click_Open_PaymentAdd() {
      (refPaymentAddModal.value as any).showComp(theDetail);
    }

      function click_Open_FlightDelete(prmFlightInfo) {
          console.log("click_Open_HotelDelete START");
          Swal.fire({
              title: 'Emin misiniz?', text: "Kaydi silmek işlemi geri alınamaz.", icon: "warning", showCancelButton: true, confirmButtonText: 'Kaydı Sil', cancelButtonText: `Vazgeç`, confirmButtonColor: '#9c1d1d', cancelButtonColor: '#3085d6',
          }).then(function (result) {
              if (result.isConfirmed) {
                  store.dispatch(Actions.RESERVATIONS_PRODUCT_DELETE, {ID: prmFlightInfo.ID, ReservationID: prmFlightInfo.ReservationID, ProductType: "FLIGHT"})
                      .then(() => {
                          Swal.fire('Silindi!', 'Kayit silme işlemi tamamlandı.', 'success').then((result)=> {
                              location.reload();
                          });
                      }).catch(() => {
                      Swal.fire({text: store.getters.getPriceListsErrors[0], confirmButtonText: "Tekrar dene!",
                          icon: "error", buttonsStyling: false, customClass: {confirmButton: "btn fw-bold btn-light-danger",},
                      });
                  });
              }
          });
      }

    function click_Open_HotelDelete(prmHotelInfo) {
      console.log("click_Open_HotelDelete START");
      Swal.fire({
        title: 'Emin misiniz?', text: "Kaydi silmek işlemi geri alınamaz.",
        icon: "warning", showCancelButton: true,
        confirmButtonText: 'Kaydı Sil', cancelButtonText: `Vazgeç`,
        confirmButtonColor: '#9c1d1d', cancelButtonColor: '#3085d6',
      }).then(function (result) {
        if (result.isConfirmed) {
            store.dispatch(Actions.RESERVATIONS_PRODUCT_DELETE, {ID: prmHotelInfo.ID, ReservationID: prmHotelInfo.ReservationID, ProductType: "HOTEL"})
              .then(() => {
                Swal.fire('Silindi!', 'Kayit silme işlemi tamamlandı.', 'success').then((result)=> {
                  location.reload();
                });
              }).catch(() => {
            Swal.fire({text: store.getters.getPriceListsErrors[0], confirmButtonText: "Tekrar dene!",
              icon: "error", buttonsStyling: false, customClass: {confirmButton: "btn fw-bold btn-light-danger",},
            });
          });
        }
      });
    }

    function click_PaymentDelete(prmTransferPayment) {
      console.log("click_PaymentDelete START ");
      Swal.fire({
        title: 'Emin misiniz?', text: "Kaydi silmek işlemi geri alınamaz.",
        icon: "warning", showCancelButton: true,
        confirmButtonText: 'Kaydı Sil', cancelButtonText: `Vazgeç`,
        confirmButtonColor: '#9c1d1d', cancelButtonColor: '#3085d6',
      }).then(function (result) {
        if (result.isConfirmed) {
          store.dispatch(Actions.RESERVATION_PAYMENT_DELETE, {ID: prmTransferPayment.ID, ReservationID: (theDetail.value as any) .ID, newReservationStatus: false,})
              .then(() => {
                Swal.fire('Silindi!', 'Kayit silme işlemi tamamlandı.', 'success')
                    .then((result)=>{
                      location.reload();
                    });
              }).catch(() => {
            Swal.fire({text: store.getters.getPriceListsErrors[0], confirmButtonText: "Tekrar dene!",
              icon: "error", buttonsStyling: false, customClass: {confirmButton: "btn fw-bold btn-light-danger",},
            });
          });
        }
      });
    }

    function click_Transfer_Delete(prmTransferInfo) {
      console.log("click_Transfer_Delete START ");
      Swal.fire({
        title: 'Emin misiniz?', text: "Kaydi silmek işlemi geri alınamaz.",
        icon: "warning", showCancelButton: true,
        confirmButtonText: 'Kaydı Sil', cancelButtonText: `Vazgeç`,
        confirmButtonColor: '#9c1d1d', cancelButtonColor: '#3085d6',
      }).then(function (result) {
        if (result.isConfirmed) {


          store.dispatch(Actions.RESERVATIONS_PRODUCT_DELETE, {ID: prmTransferInfo.ID, ReservationID: prmTransferInfo.ReservationID, ProductType: "TRANSFER"})
              .then(() => {
                Swal.fire('Silindi!', 'Kayit silme işlemi tamamlandı.', 'success')
                    .then((result)=>{
                      location.reload();
                    });
              }).catch(() => {
                Swal.fire({text: store.getters.getPriceListsErrors[0], confirmButtonText: "Tekrar dene!",
                    icon: "error", buttonsStyling: false, customClass: {confirmButton: "btn fw-bold btn-light-danger",},
                });
            });
        }
      });
    }

    function click_Kplus_StartProcess() {
        console.log("click_Kplus_StartProcess START");

        store.dispatch(Actions.RESERVATIONS_KPLUS_PROCESS_START, {ReservationID: paramId.value}).then((prmData) => {
            console.log(prmData);
            console.log(prmData.Result.PrsResult);
            if (prmData.Result.PrsResult==="DONE") {
                //libTools.method.swalSuccess("Tamam", "KPlus Kodu Başarılı Şekilde Gönderildi. Pnr: "+prmData.Result.Value);

                (refKPlusDetailModal.value as any).setResult(prmData.ReservationID, prmData.Token, prmData.Result);

            } else {
                Swal.fire({html:"<b>Sorun Oluştu</b>: <br> "+prmData.Result.Messages[0], confirmButtonText: "Tamam!",
                    icon: "error", buttonsStyling: false, customClass: {confirmButton: "btn fw-bold btn-light-danger",},
                });
            }

        }).catch(() => {
            //
        });

    }

    return {
      dateConvert, libTools,
      paramId,
      theDetail,
      dataReservationsDetails,
      arrPaxes,
      getItems,
      getFirstPax,
      refHotelVoucherModal, refFlightVoucherModal, refTransferVoucherModal, refReservationVoucherModal,
      click_Open_VoucherHotel, click_Open_VoucherFlight, click_Open_VoucherTransfer, click_Open_VoucherReservation,
        click_Kplus_StartProcess,
      refTransferUpdateModal,
      click_Open_TransferUpdate,
      refHotelAddModal, refHotelUpdateModal,
      click_Open_HotelAdd, click_Open_HotelUpdate, click_Open_HotelDelete,
      refFlightUpdateModal,
      click_Open_FlightUpdate,
        click_Open_FlightDelete,
      refPaymentAddModal,
      click_Open_PaymentAdd,
      click_PaymentDelete,
      click_Transfer_Delete,
        refKPlusDetailModal,
      refRemarks,
      id,
    };
  },


});
