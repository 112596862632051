
import {computed, defineComponent, onMounted, ref} from "vue";
import Currencies from "@/components/general-modals/cards/Currencies.vue";
import HotelRoomConcept from "@/components/general-modals/cards/HotelRoomConcept.vue";
import RoomCount from "@/components/general-modals/cards/RoomCount.vue";
import RoomDetail from "@/components/general-modals/cards/RoomDetail2.vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import AppCore from "@/core/appcore/AppCore";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
    name: "Reservation Modal",
    components: {
        Currencies,
        HotelRoomConcept,
        RoomCount,
        RoomDetail,
    },
    props: {
        reservationid: {type:Number, required:true},
    },
    setup(props) {

        let refModal = ref<null | HTMLElement>(null);
        let refComp_Currencies = ref(null);
        let refComp_HotelRoomConcept = ref(null);
        let refComp_RoomCount = ref(null);
        let refComp_RoomDetails = [];

        let ccvRoomCount = ref(0);

        var theReservation = ref({});

        onMounted(()=>{
            //
        });

        function onRoomCountChanged(prmRoomCount) {
            refComp_RoomDetails = [];
            ccvRoomCount.value = prmRoomCount;
        }

        const addRef_RoomDetails = el => {
            if (el) {
                //console.log("addRef_RoomDetails A roomid: "+(el as any).roomid);
                refComp_RoomDetails.push(el);
                if (theReservation.value!==undefined && theReservation.value!==null) {
                    (el as any).initContent(theReservation);
                }
            }
        }

        function setContent(prmReservation) {
            theReservation.value = prmReservation.value;
            //console.log("ReeservationHotelAddModal setContent "+JSON.stringify(prmReservation.value));
            (refComp_Currencies.value as any).setContent(theReservation.value);
        }

        function onRoomDetail_DatesIntervalChanged(prmRoomId, prmInterval) {
            //console.log("onRoomDetail_DatesIntervalChanged prmRoomId: "+prmRoomId+" prmInterval: "+prmInterval);
        }

        function onRoomDetail_PaxCountChanged(prmRoomId, prmCoADT, prmCoCHD, prmCoINF) {
            //console.log("onRoomDetail_PaxCountChanged "+prmRoomId+" prmCoADT: "+prmCoADT+" prmCoCHD: "+prmCoCHD+" prmCoINF: "+prmCoINF);
        }

        function click_Add() {
            processSend();
        }

        function processSend() {
            let rv = {isok : true, result: {currencies:{}, hotel:{}, roomcount:{}, rooms:[] }};

            //Currencies
            if (rv.isok){
                let resCurrencies  = (refComp_Currencies.value as any).getResults();
                if (resCurrencies.isok) {
                    rv.result.currencies = resCurrencies.result;
                } else {
                    rv.isok = false;
                }
            }

            //HotelRoomConcept
            if (rv.isok){
                let resHotelRoomConcept  = (refComp_HotelRoomConcept.value as any).getResults();
                if (resHotelRoomConcept.isok) {
                    rv.result.hotel = resHotelRoomConcept.result;
                } else {
                    rv.isok = false;
                }
            }

            //RoomCount
            if (rv.isok){
                let resRoomCount  = (refComp_RoomCount.value as any).getResults();
                if (resRoomCount.isok) {
                    rv.result.roomcount = resRoomCount.result;
                } else {
                    rv.isok = false;
                }
            }

            //RoomDetails
            if (rv.isok) {
                for (let i=0; i<ccvRoomCount.value; i++) {
                    if (rv.isok){
                        let resRoom =  (refComp_RoomDetails[i] as any).getResults();
                        if (resRoom.isok) {
                            rv.result.rooms.push(resRoom.room);
                        } else {
                            rv.isok = false;
                        }
                    }
                    //console.log("click_Add roomid: "+(refComp_RoomDetails[i] as any).roomid);
                }
            }

            console.log("click_Add v1 rv: "+JSON.stringify(rv));

            //SUCCESS
            if (rv.isok) {
                console.log("click_Add SUCCESS DONE");
                AppCore.prsCore_Reservation.send_ReservationProduct_Manual(props.reservationid, "HOTEL", rv.result);
            }
        }

        const dataReservationProductsResultInsertManual = computed(() => {
            let dataComputed =store.getters.getReservationProductsResultInsertManual;
            if (dataComputed!=undefined ) {
                checkResult(dataComputed);
            }
            return dataComputed;
        });

        function checkResult(prmData) {
            console.log(prmData);
            if (prmData.ProductType==="HOTEL") {
                //libTools.method.swalSuccess("Tamam", "Otel Reservasyonu Eklendi");
                Swal.fire('Güncellendi!', 'Kayit ekleme işlemi tamamlandı.', 'success').then((result)=>{
                    location.reload();
                });
                hideModal(refModal.value);
            }
        }

        return {
            refModal,
            refComp_Currencies,
            refComp_HotelRoomConcept,
            refComp_RoomCount,
            onRoomCountChanged,
            refComp_RoomDetails, addRef_RoomDetails,
            ccvRoomCount,
            onRoomDetail_DatesIntervalChanged,
            onRoomDetail_PaxCountChanged,
            click_Add,
            dataReservationProductsResultInsertManual,
            setContent,
        };

    },



});
