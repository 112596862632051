
import {computed, defineComponent, onMounted, ref,} from "vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Field, Form} from "vee-validate";


export default defineComponent({
  name: "bank-account-update",
  components: {
    Field,
    Form,
  },


  props: {
    itemID: String,
  },

  watch: {
    itemID() {
      const payloadit = {
        "ID": this.itemID
      };

      store.dispatch(Actions.RESERVATIONS_ALL_DETAILS, payloadit);
    },
  },


  setup(props) {

    store.dispatch(Actions.ACCOUNT_LIST_ALL, {});
    const myAccount = computed(() => {
      return store.getters.accountList;
    });

    store.dispatch(Actions.COMPANY_DEPARTMENTS_LIST, {});
    const myDepartment = computed(() => {
      return store.getters.DepartmentsList;
    });

    store.dispatch(Actions.RESERVATIONS_ALL_DETAILS, {'ID': props.itemID});


    const submitButtonRef = ref<null | HTMLButtonElement>(null);

    const dataReservationUpdateDetails = computed(() => {
      let dataComputed = store.getters.reservationsDetails;
      if (dataComputed !== undefined) {
        setMyDetails(dataComputed);
      }
      return dataComputed;
    })



    function setMyDetails(data) {
      myDetails.value = data;
    }

    const myDetails = ref({});

    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }


      const payload1 = {
        ID: props.itemID,
        AccountID: (myDetails.value as any).AccountID,
        Department: (myDetails.value as any).Department,
        AccountVoucherNo: (myDetails.value as any).AccountVoucherNo,
        ReservationNote: (myDetails.value as any).ReservationNote,
      }


      store.dispatch(Actions.RESERVATIONS_EDIT, payload1)
          .then(() => {
            store.dispatch(Actions.RESERVATIONS_ALL_DETAILS, {});
            Swal.fire({
              text: "Tebrikler. Başarılı bir şekilde işlemi tamamladınız.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Geri Dön!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              submitButtonRef.value.disabled = false;
              submitButtonRef.value.setAttribute("data-kt-indicator", "off");
            });
          }).catch(() => {
        Swal.fire({
          text: store.getters.getReservationsErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tekrar dene!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        submitButtonRef.value.disabled = false;
        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
      });
    }

    return {
      submitButtonRef,
      submit,
      myDetails,
      myAccount,
      myDepartment,
      dataReservationUpdateDetails,

    };
  },
});
