
import {defineComponent, ref} from "vue";
import curList from "@/core/data/currency";
import {libTools} from "@/core/appcore/libs/Lib_Tools";


export default defineComponent({
    name: "Hotel-Currencies",
    methods: {
        getResults() {
            console.log("Currencies getResults");
            let rv = this.confirmValues();
            return rv;
        },
    },
    props : {
        idCode: String,
        ProcessType: String,
    },
    setup(props) {

        let theReservation = ref({});

        function setContent(prmReservation) {
            theReservation.value = prmReservation;
            libTools.method.setElValue("sel_HotelReservationAdd_Currency_Sale_"+props.idCode, (theReservation.value as any).SaleCur);
            libTools.method.setElValue("sel_HotelReservationAdd_Currency_Cost_"+props.idCode, (theReservation.value as any).SaleCur);

            if (props.ProcessType==="Add") {
                (document.getElementById("sel_HotelReservationAdd_Currency_Sale_"+props.idCode) as HTMLInputElement).disabled = true;
            }
        }

        //##Confirm
        function confirmValues() {
            let rv = {isok: true, result: {},};

            if (rv.isok && libTools.method.getElValue("sel_HotelReservationAdd_Currency_Sale_"+props.idCode)==="") {
                libTools.method.swalError("Tamam", "Satış Kuru seçilmemiş");
                rv.isok = false;
            }

            if (rv.isok && libTools.method.getElValue("sel_HotelReservationAdd_Currency_Cost_"+props.idCode)==="") {
                libTools.method.swalError("Tamam", "Maliyet Kuru seçilmemiş");
                rv.isok = false;
            }

            if (rv.isok) {
                rv.result= {
                    CurSale: libTools.method.getElValue("sel_HotelReservationAdd_Currency_Sale_"+props.idCode),
                    CurCost: libTools.method.getElValue("sel_HotelReservationAdd_Currency_Cost_"+props.idCode),
                };
            }

            return rv;
        }

        return {
            curList,
            confirmValues,
            setContent,
        };
    },
});
