

import {defineComponent, ref} from "vue";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import store from "@/store";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {Actions} from "@/store/enums/StoreEnums";

export default defineComponent({
    setup() {

        var arrRooms = ref([]);
        var theResult = ref({});
        var ccvReservationID = ref(0);
        var ccvToken = ref("");

        let selRoomCode = "";

        function setResult(prmReservationID, prmToken, prmResult) {
            console.log("KPlusDetailModal setValues prmReservationID: "+prmReservationID+" prmResult: "+JSON.stringify(prmResult));
            ccvReservationID.value = prmReservationID;
            ccvToken.value = prmToken;
            theResult.value = prmResult;
            arrRooms.value = prmResult.Value;

            selRoomCode = "";
        }

        function click_Radio(prmRoomCode) {
            selRoomCode = prmRoomCode;
        }

        function click_Send() {
            console.log("click_Send selRoomCode: "+selRoomCode);

            if (selRoomCode!="") {
                store.dispatch(Actions.RESERVATIONS_KPLUS_PROCESS_START_BOOKING, {ReservationID: ccvReservationID.value, Token: ccvToken.value, RoomCode: selRoomCode}).then((prmData) => {
                    console.log(prmData);
                    console.log(prmData.Result.PrsResult);
                    if (prmData.Result.PrsResult==="DONE") {
                        libTools.method.swalSuccess("Tamam", "KPlus Kodu Başarılı Şekilde Gönderildi. Pnr: "+prmData.Result.Value);
                    } else {
                        Swal.fire({html:"<b>Sorun Oluştu</b>: <br> "+prmData.Result.Messages[0], confirmButtonText: "Tamam!",
                            icon: "error", buttonsStyling: false, customClass: {confirmButton: "btn fw-bold btn-light-danger",},
                        });
                    }

                }).catch(() => {
                    //
                });

            } else {
                libTools.method.swalError("Tamam","Oda Seçilmemiş")
            }


        }

        return {
            libTools,
            setResult,
            ccvReservationID,
            theResult,
            arrRooms,
            click_Radio,
            click_Send,
        };
    },
});

