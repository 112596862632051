
import {defineComponent, ref} from "vue";
import { dateConvert } from "@/core/filters/datatime";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import {jsPDF} from "jspdf";
import UploadFile from "@/components/appcomps/uploadfiles/UploadFile.vue";
import SendEmail from "@/components/appcomps/sendemail/SendEmail.vue";

export default defineComponent({
    name: "reservation-flight-voucher-modal2",
    components: {
        UploadFile,
        SendEmail,
    },
    setup() {
        var theReservation = ref({});
        var theFlight = ref({});
        var arrPaxes = ref ([]);
        var thePax = ref({});
        var theFlightTimes = ref({});

        const refBtnExportToPdf = ref(null);
        const refBtnSendEmail = ref(null);
        const refFileUpload = ref(null);
        const refSendEmail = ref(null);

        var pWindow;

        function setFlight(prmReservation, prmFlight, prmPaxes) {
            console.log("FlightVoucherModal setFlight START "+JSON.stringify(prmFlight));
            theReservation.value = prmReservation.value;
            theFlight.value = prmFlight;
            arrPaxes.value = prmPaxes;
            thePax.value = libTools.method.getParsedObj(prmFlight.RemarksJSON1);
            theFlightTimes.value = libTools.method.getRemarksJSONFlightTimes(prmFlight.RemarksJSON3);
        }

        function printModal() {

            var divContents = document.getElementById("divFlight_Voucher_AllPrintable").innerHTML;
            pWindow = window.open('', '', '');
            pWindow.document.write('<html><body>');
            pWindow.document.write(divContents);
            pWindow.document.write('</body></html>');
            pWindow.document.close();

            setTimeout(()=>{pWindow.print();}, 500);
        }

        function click_ExportToPdf(prmType, prmMailTo) {
            let elTarget = document.getElementById("divFlight_Voucher_AllPrintable");
            elTarget.innerHTML = libTools.method.convertTurkishToEnglish(elTarget.innerHTML);

            if (prmType==="Export") {
                refBtnExportToPdf.value.disabled = true;
                refBtnExportToPdf.value.setAttribute("data-kt-indicator", "on");
            } else {
                refBtnSendEmail.value.disabled = true;
                refBtnSendEmail.value.setAttribute("data-kt-indicator", "on");
            }


            setTimeout(()=>{ exportToPdf(prmType, prmMailTo); }, 500);
        }

        function click_SendEmail() {
            (refSendEmail.value as any).showComp("");
        }

        function emailEntered(prmEmail) {
            click_ExportToPdf("SendEmail", prmEmail);
        }

        function exportToPdf(prmType, prmMailTo) {
            const doc = new jsPDF('l', 'mm', [1150, 1100]);
            let elTarget = document.getElementById("divFlight_Voucher_AllPrintable");
            //let elNew = document.createElement("div");
            //elNew.innerHTML = libTools.method.convertTurkishToEnglish(vvText.innerHTML);
            doc.html(elTarget,
                {
                    callback: function(doc) {
                        let vvRes = theReservation.value as any;
                        let vvFlight = theFlight.value as any;
                        let vvKindName = 'reservations';
                        let vvDirName = vvRes.VoucherNo.replaceAll(".", "-");
                        let vvFileName = "FlightVoucher"+vvRes.VoucherNo.replaceAll(".", "_")+vvFlight.ID+".pdf";
                        let vvMailTo = prmMailTo;
                        let vvContent = "Merhabalar, Flight Voucher ("+vvFlight.ID+") bilgileri ektedir";
                        let vvToName = "Flight Voucher "+vvFlight.ID;
                        let vvFromName = "Flight Voucher "+vvFlight.ID;
                        let vvSubject = "Flight Voucher "+vvFlight.ID;

                        if (prmType==="Export") {
                            doc.save(vvFileName);
                            refBtnExportToPdf.value.disabled = false;
                            refBtnExportToPdf.value.setAttribute("data-kt-indicator", "off");
                        }
                        if (prmType==="SendEmail") {
                            var vvFile = doc.output('blob');
                            (refFileUpload.value).addFileAndSendEmail(vvFile, vvKindName, vvDirName, vvFileName, "HOTELVOUCHER", vvMailTo, vvToName, vvFromName, vvSubject, vvRes.AccountID, vvRes.ID, vvContent);

                        }

                    },
                    x: 10,
                    y: 4,
                    image: { type: "png", quality: 0.98 }, // as per req
                    html2canvas: { letterRendering: true, useCORS: true },
                }
            );
        }

        function onSuccessUploadFile() {
            refBtnSendEmail.value.disabled = false;
            refBtnSendEmail.value.setAttribute("data-kt-indicator", "off");
        }

        function onErrorUploadFile(prmErrorCode) {
            refBtnSendEmail.value.disabled = false;
            refBtnSendEmail.value.setAttribute("data-kt-indicator", "off");
        }

        return {
            dateConvert,
            libTools,
            theReservation,
            theFlight,
            theFlightTimes,
            arrPaxes,
            thePax,
            setFlight,
            printModal,
            refBtnExportToPdf, click_ExportToPdf,
            refFileUpload, refBtnSendEmail, refSendEmail,
            onSuccessUploadFile, onErrorUploadFile,
            click_SendEmail, emailEntered,
        };
    },
});

