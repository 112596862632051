
import {defineComponent, ref} from "vue";
import RemarkTab from "@/components/general-modals/cards/RemarkTab.vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";

export default defineComponent({
    name: "Reservation-Remarks",
    components: {
        RemarkTab,
    },
    setup() {

        const refRemarkTab_Accounting = ref(null);
        const refRemarkTab_Account = ref(null);
        const refRemarkTab_Operation = ref(null);
        const refRemarkTab_Hotel = ref(null);

        let theReservation = ref({});

        function initComp(prmReservation) {
            theReservation.value = prmReservation.value;
            //console.log("Remarks intiComp "+JSON.stringify(theReservation.value));
            (refRemarkTab_Accounting.value as any).initComp(prmReservation);
            (refRemarkTab_Account.value as any).initComp(prmReservation);
            (refRemarkTab_Operation.value as any).initComp(prmReservation);
            (refRemarkTab_Hotel.value as any).initComp(prmReservation);

            store.dispatch(Actions.REMARK_LIST, {Type:"ReservationDetail", ObjectID: (theReservation.value as any).ID});
        }

        return {
            initComp,
            refRemarkTab_Accounting, refRemarkTab_Account, refRemarkTab_Operation, refRemarkTab_Hotel,
        };
    },
});

