import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "row mx-0 " }
const _hoisted_2 = { class: "card-body p-7 mt-2" }
const _hoisted_3 = { class: "ribbon-label bg-primary" }
const _hoisted_4 = { class: "form-floating" }
const _hoisted_5 = {
  for: "floatingTextarea1",
  class: "text-primary"
}
const _hoisted_6 = { class: "text-end" }
const _hoisted_7 = { style: {"display":"none"} }
const _hoisted_8 = { style: {"display":"none"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.arrRemarks, (eachRemark, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "card card-bordered ribbon ribbon-top mb-5 ",
          key: index
        }, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(eachRemark.Remark), 1),
          _createElementVNode("div", _hoisted_3, _toDisplayString(eachRemark.Username) + " - " + _toDisplayString(_ctx.dateConvert.method.convertToDateFormated(eachRemark.RemarkDate, "DD-MM-YYYY HH:mm:ss")), 1)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _withDirectives(_createElementVNode("textarea", {
        class: "form-control fs-7 fw-normal",
        placeholder: "Not Yazınız",
        id: "floatingTextarea1",
        style: {"height":"100px"},
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.ccvRemark = $event))
      }, null, 512), [
        [_vModelText, _ctx.ccvRemark]
      ]),
      _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.remarkLabel) + " Notu", 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("button", {
        class: "btn btn-sm btn-primary mt-5 mt-xl-10",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.click_Send_Remark && _ctx.click_Send_Remark(...args)))
      }, "Notu Onayla")
    ]),
    _createElementVNode("p", _hoisted_7, "theReservation: " + _toDisplayString(_ctx.theReservation), 1),
    _createElementVNode("p", _hoisted_8, "dataRemarkList: " + _toDisplayString(_ctx.dataRemarkList), 1)
  ], 64))
}