
import {computed, defineComponent, ref} from "vue";
import { Field} from "vee-validate";
import paymentMethods from "@/core/data/paymentMethods";
import curList from "@/core/data/currency";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {dateConvert} from "@/core/filters/datatime";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import Swal from "sweetalert2/dist/sweetalert2.js";

interface IPaymentInfo {
    itemPaymentStatus: boolean;
    itemPaymentType: string;
    itemPaymentMethod: string;
    itemPaymentAmount: string;
    itemPaymentCur: string;
    itemPaymentDescription: string;
}

export default defineComponent({
    name: "Reservation-Payment-Add-Modal",
    components: {
        Field,
    },
    setup() {

        var theReservation = ref({});

        const paymentInfo = ref<IPaymentInfo>({
            itemPaymentStatus: true,
            itemPaymentType: "",
            itemPaymentMethod: "",
            itemPaymentAmount: "",
            itemPaymentCur: "",
            itemPaymentDescription: "",
        });

        function showComp(prmReservation) {
            theReservation.value = prmReservation.value;
            //console.log("showComp theReservation: "+JSON.stringify(theReservation.value));
            store.dispatch(Actions.BANKPOS_LIST, {});
            store.dispatch(Actions.BANKACCOUNTS_LIST, {});
            store.dispatch(Actions.CUR_RATE_LIST, {Type: 'AUTO', validDate: dateConvert.method.getTodayYMD(), is_active: 'active', limit: 1,});
        }

        const myBankAccount = computed(() => {
            return store.getters.bankAccountsList;
        });

        const myBankPos = computed(() => {
            return store.getters.bankPosList;
        });

        const myCurRateList = computed(() => {
            let tmpData = store.getters.curRateList;
            return setCurData(tmpData);
        });

        function setCurData(tmpData){
            if (tmpData.data !== undefined) {
                return tmpData.data[0];
            } else {
                return {CurTRY: 1, CurUSD: 0, CurEUR: 0, CurSTG: 0,}
            }
        }

        function click_Add() {
            let vvPayload = {
                ReservationID: (theReservation.value as any).ID,
                DepartmentID : (theReservation.value as any).Department,
                AccountID : (theReservation.value as any).AccountID,
                Method: paymentInfo.value.itemPaymentType,
                MethodID: getMethodID(),
                Amount: paymentInfo.value.itemPaymentAmount,
                Cur: paymentInfo.value.itemPaymentCur,
                CurRate: JSON.stringify(getCurRate()),
                PaymentDate: dateConvert.method.convertToDateFormated(new Date(), "YYYY-MM-DD"),
                Description: paymentInfo.value.itemPaymentDescription,
                newReservationStatus: false,
            };
            console.log("click_Add vvPayload: "+JSON.stringify(vvPayload));

            store.dispatch(Actions.PAYMENT_INSERT, vvPayload).then((prmData) => {
                console.log(prmData);
                if (prmData.data.status===201) {
                    Swal.fire('Eklendi!', 'Kayit ekleme işlemi tamamlandı.', 'success').then((result)=>{
                        location.reload();
                    });
                } else {
                    libTools.method.swalError("Tamam", "Hata Oluştu");
                }
            }).catch(() => {
                libTools.method.swalError("Tamam", "Hata Oluştu");
            });
        }

        function getCurRate() {
            return {
                TRY: myCurRateList.value.CurTRY,
                USD: myCurRateList.value.CurUSD,
                EUR: myCurRateList.value.CurEUR,
                STG: myCurRateList.value.CurSTG,
            };
        }

        function getMethodID() {
            let rv = "0";
            if (paymentInfo.value.itemPaymentType==="CARD" || paymentInfo.value.itemPaymentType==="BANK") {
                rv = paymentInfo.value.itemPaymentMethod;
            }
            return rv;
        }

        return {
            paymentMethods, curList, paymentInfo,
            showComp,
            myBankAccount, myBankPos,
            myCurRateList,
            click_Add,
        };
    },

});
