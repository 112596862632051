
import {computed, defineComponent, onMounted, ref} from "vue";
import { Field} from "vee-validate";
import genderTypes from "@/core/data/gender";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {dateConvert} from "@/core/filters/datatime";
import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';
import {Turkish} from "flatpickr/dist/l10n/tr"
import {libTools} from "@/core/appcore/libs/Lib_Tools";


interface paxTransferDetail {

    reservationProductId: number,

    transferCode: string;
    transferType: string;
    typeOfTransfer: string;
    transferLocationA: string;
    transferLocationB: string;
    trsADTCount: number;
    trsCHDCount: number;
    trsINFCount: number;
    transferDate: string;
    transferTime: string;
    flightNo: string;
    flyDepartureTime: string;
    flyArrivalTime: string;
    transferPayment: number;
    transferCost: number;
    VIPType: string;


    roomCategory: string;
    itemConcept: string;
    paxADTGender: Array<any>;
    paxADTID: Array<any>;
    paxADTName: Array<any>;
    paxADTSurname: Array<any>;
    paxADTBDate: Array<any>;
    paxADTPhone: Array<any>;
    paxADTEmail: Array<any>;
    paxCHDGender: Array<any>;
    paxCHDID: Array<any>;
    paxCHDName: Array<any>;
    paxCHDSurname: Array<any>;
    paxCHDBDate: Array<any>;
    paxINFGender: Array<any>;
    paxINFID: Array<any>;
    paxINFName: Array<any>;
    paxINFSurname: Array<any>;
    paxINFBDate: Array<any>;
    srcInDate: string;
    srcOutDate: string;
}

export default defineComponent({
    name: "pax-transfer-detail",
    components: {
        Field,
    },
    methods: {
        setContent(prmTransferInfo) {
            //console.log("PaxTransferDetail setContent "+JSON.stringify(prmTransferInfo));
            this.setContentInner(prmTransferInfo);
        },
        getResults() {
            let rv = {isok: true, result:{}};

            if (rv.isok) {
                let resA = this.confirmValues();
                if (resA.isok) {
                    rv.result= resA.result;
                } else {
                    rv.isok = false;
                }
            }
            return rv;
        },
    },
    props: {
        IdCode:String,
        hotelID: String,
        hotelDetails: Object,
        ID: String,
        transferIndex: Number,
    },
    setup(props, ctx) {

        let fpickTransferDate;

        store.dispatch(Actions.TRANSFER_LIST, {});
        const dataTransfersList = computed(() => {
            return store.getters.transfersList;
        });


        store.dispatch(Actions.TRANSFER_LOCATIONS_LIST, {});
        const dataTransferLocationsList = computed(() => {
            return store.getters.transferLocationsList;
        });

        onMounted(() => {
            initDatePicker(null)
        });

        function initDatePicker(prmStartDate) {
            let myConfigS = {
                "locale": Turkish,
                dateFormat: "d-m-Y (D)",
                defaultDate: 'today',
            };
            let cfgTransferDate = {
                "locale": Turkish,
                //dateFormat: "d-m-Y (D)",
                dateFormat: "Y-m-d",
                defaultDate: prmStartDate!==null ? dateConvert.method.dateToflatpicker(prmStartDate) : "",
                minDate: "today",

            };
            fpickTransferDate = flatpickr("#txtTransferDate_"+props.IdCode+"_"+props.transferIndex, cfgTransferDate);
            let tmpSrcFromDate = flatpickr("#srcInDate", myConfigS);
            let tmpSrcToDate = flatpickr("#srcOutDate", myConfigS);
        }

        const paxTransferDetail = ref<paxTransferDetail>({

            reservationProductId: 0,

            transferCode: "",
            transferType: "",
            typeOfTransfer: "",
            transferLocationA: "",
            transferLocationB: "",
            trsADTCount: 1,
            trsCHDCount: 0,
            trsINFCount: 0,
            transferDate: dateConvert.method.dateToflatpicker(dateConvert.method.getTodayYMD()),
            transferTime: "",
            flightNo: "",
            flyDepartureTime: "",
            flyArrivalTime: "",
            transferPayment: 0,
            transferCost: 0,
            VIPType: "",

            roomCategory: "",
            itemConcept: "",
            paxADTGender: [],
            paxADTID: [],
            paxADTName: [],
            paxADTSurname: [],
            paxADTBDate: [],
            paxADTPhone: [],
            paxADTEmail: [],
            paxCHDGender: [],
            paxCHDID: [],
            paxCHDName: [],
            paxCHDSurname: [],
            paxCHDBDate: [],
            paxINFGender: [],
            paxINFID: [],
            paxINFName: [],
            paxINFSurname: [],
            paxINFBDate: [],
            srcInDate: dateConvert.method.dateToflatpicker(dateConvert.method.getTodayYMD()),
            srcOutDate: dateConvert.method.dateToflatpicker(dateConvert.method.getTodayYMD()),
        });

        function getTransferLocations(prmDir) {
            let arrRes = [];
            if (dataTransferLocationsList.value!==undefined && (dataTransferLocationsList.value as any).data!==undefined) {
                for (var i=0; i<(dataTransferLocationsList.value as any).data.length; i++) {
                    let each = (dataTransferLocationsList.value as any).data[i];
                    if (paxTransferDetail.value.transferType==="ARRIVAL") {
                        if (prmDir === "A" && (each as any).Type === "AIRPORT") {
                            arrRes.push(each);
                        }
                        if (prmDir === "B" && (each as any).Type === "LOCATION") {
                            arrRes.push(each);
                        }
                    }

                    if (paxTransferDetail.value.transferType==="RETURN") {
                        if (prmDir === "A" && (each as any).Type === "LOCATION") {
                            arrRes.push(each);
                        }
                        if (prmDir === "B" && (each as any).Type === "AIRPORT") {
                            arrRes.push(each);
                        }
                    }


                }
            }
            return arrRes;
        }

        function getTransferTime(prmTransferInfo) {
            let vvRemarksJSON3 = JSON.parse(prmTransferInfo.RemarksJSON3);
            if (vvRemarksJSON3.TransferTime!=="") {
                return vvRemarksJSON3.TransferTime;
            } else {
                if (prmTransferInfo.RemarksVC3==="ARRIVAL") {
                    return vvRemarksJSON3.FlightArrTime;
                }
                if (prmTransferInfo.RemarksVC3==="RETURN") {
                    return vvRemarksJSON3.FlightDepTime;
                }
            }
        }

        function setContentInner(prmTransferInfo) {
            let vvRemarksJSON1 = JSON.parse(prmTransferInfo.RemarksJSON1);
            let vvRemarksJSON3 = JSON.parse(prmTransferInfo.RemarksJSON3);
            console.log("PAxTransferDetail setContentInner CHDCount " + prmTransferInfo.CHDCount);

            paxTransferDetail.value.reservationProductId = Number(prmTransferInfo.ID);
            paxTransferDetail.value.transferCode = prmTransferInfo.RemarksINT2;
            paxTransferDetail.value.transferType = prmTransferInfo.RemarksVC3;
            paxTransferDetail.value.transferDate = prmTransferInfo.StartDate;
            initDatePicker( paxTransferDetail.value.transferDate);

            paxTransferDetail.value.transferTime = getTransferTime(prmTransferInfo);
            paxTransferDetail.value.transferLocationA = vvRemarksJSON3.TransferLocationA;
            paxTransferDetail.value.transferLocationB = vvRemarksJSON3.TransferLocationB;
            paxTransferDetail.value.flyDepartureTime = vvRemarksJSON3.FlightDepTime;
            paxTransferDetail.value.flyArrivalTime = vvRemarksJSON3.FlightArrTime;
            paxTransferDetail.value.flightNo = vvRemarksJSON3.FlightNo;
            paxTransferDetail.value.transferPayment = prmTransferInfo.TotalPrice;
            paxTransferDetail.value.transferCost = prmTransferInfo.TotalCost;
            paxTransferDetail.value.VIPType = vvRemarksJSON3.VIPType;
            paxTransferDetail.value.trsADTCount = prmTransferInfo.ADTCount;
            paxTransferDetail.value.trsCHDCount = prmTransferInfo.CHDCount;


            //console.log(vvRemarksJSON1);

            let vvIndexADT = 0;
            let vvIndexCHD = 0;
            for (let i=0; i<vvRemarksJSON1.length; i++) {
                if (vvRemarksJSON1[i]!==undefined) {
                    if (vvRemarksJSON1[i].PaxType!==undefined) {
                        if (vvRemarksJSON1[i].PaxType==='ADT') {
                            paxTransferDetail.value.paxADTGender[vvIndexADT] = libTools.method.getValueOrEmptyString(vvRemarksJSON1[i].PaxGender);
                            paxTransferDetail.value.paxADTName[vvIndexADT] = libTools.method.getValueOrEmptyString(vvRemarksJSON1[i].PaxName);
                            paxTransferDetail.value.paxADTSurname[vvIndexADT] = libTools.method.getValueOrEmptyString(vvRemarksJSON1[i].PaxSurname);
                            paxTransferDetail.value.paxADTID[vvIndexADT] = libTools.method.getValueOrEmptyString(vvRemarksJSON1[i].PaxID);
                            paxTransferDetail.value.paxADTBDate[vvIndexCHD] = libTools.method.getValueOrEmptyString(dateConvert.method.convertToDateFormated(vvRemarksJSON1[i].PaxBirthdate, "DD-MM-YYYY"));
                            paxTransferDetail.value.paxADTPhone[vvIndexCHD] = libTools.method.getValueOrEmptyString(vvRemarksJSON1[i].PaxADTPhone);
                            paxTransferDetail.value.paxADTEmail[vvIndexCHD] = libTools.method.getValueOrEmptyString(vvRemarksJSON1[i].PaxADTEmail);
                            vvIndexADT++;
                        }
                        if (vvRemarksJSON1[i].PaxType==='CHD') {
                            paxTransferDetail.value.paxCHDGender[vvIndexCHD] = libTools.method.getValueOrEmptyString(vvRemarksJSON1[i].PaxGender);
                            paxTransferDetail.value.paxCHDName[vvIndexCHD] = libTools.method.getValueOrEmptyString(vvRemarksJSON1[i].PaxName);
                            paxTransferDetail.value.paxCHDSurname[vvIndexCHD] = libTools.method.getValueOrEmptyString(vvRemarksJSON1[i].PaxSurname);
                            paxTransferDetail.value.paxCHDID[vvIndexCHD] = libTools.method.getValueOrEmptyString(vvRemarksJSON1[i].PaxID);
                            paxTransferDetail.value.paxCHDBDate[vvIndexCHD] = libTools.method.getValueOrEmptyString(dateConvert.method.convertToDateFormated(vvRemarksJSON1[i].PaxBirthdate, "DD-MM-YYYY"));
                            vvIndexCHD++;
                        }
                        if (vvRemarksJSON1[i].PaxType==='INF') {
                          paxTransferDetail.value.paxINFGender[vvIndexCHD] = libTools.method.getValueOrEmptyString(vvRemarksJSON1[i].PaxGender);
                          paxTransferDetail.value.paxINFName[vvIndexCHD] = libTools.method.getValueOrEmptyString(vvRemarksJSON1[i].PaxName);
                          paxTransferDetail.value.paxINFSurname[vvIndexCHD] = libTools.method.getValueOrEmptyString(vvRemarksJSON1[i].PaxSurname);
                          paxTransferDetail.value.paxINFID[vvIndexCHD] = libTools.method.getValueOrEmptyString(vvRemarksJSON1[i].PaxID);
                          paxTransferDetail.value.paxINFBDate[vvIndexCHD] = libTools.method.getValueOrEmptyString(dateConvert.method.convertToDateFormated(vvRemarksJSON1[i].PaxBirthdate, "DD-MM-YYYY"));
                          vvIndexCHD++;
                        }
                    }
                }
            }




        }



        function confirmValues() {
            let rv = {isok : true, result:{},};

            //console.log("confirmValues TransAName: "+(refSelTransferLocationA.value as HTMLInputElement).value);
            //console.log("confirmValues TransAName: "+(document.getElementById("selTransferLocationA_"+props.IdCode+"_"+props.transferIndex) as HTMLInputElement).text);
            //console.log("confirmValues TransAName: "+libTools.method.getElSelectedText("selTransferLocationA_"+props.IdCode+"_"+props.transferIndex));

            if (rv.isok && paxTransferDetail.value.transferCode==="") {
                libTools.method.swalError("Tamam", "Transfer " +props.transferIndex+ " için 'Transfer Kodu' seçilmemiş");
                rv.isok = false;
            }

            if (rv.isok && paxTransferDetail.value.transferType==="") {
                libTools.method.swalError("Tamam", "Transfer " +props.transferIndex+ " için 'Transfer Tipi' seçilmemiş");
                rv.isok = false;
            }

            if (rv.isok && paxTransferDetail.value.transferLocationA==="") {
                libTools.method.swalError("Tamam", "Transfer " +props.transferIndex+ " için 'Nereden' seçilmemiş");
                rv.isok = false;
            }

            if (rv.isok && paxTransferDetail.value.transferLocationB==="") {
                libTools.method.swalError("Tamam", "Transfer " +props.transferIndex+ " için 'Nereye' seçilmemiş");
                rv.isok = false;
            }

            if (rv.isok && paxTransferDetail.value.transferDate==="") {
                libTools.method.swalError("Tamam", "Transfer " +props.transferIndex+ " için 'Transfer Tarihi' seçilmemiş");
                rv.isok = false;
            }

            if (rv.isok && paxTransferDetail.value.transferTime==="") {
                libTools.method.swalError("Tamam", "Transfer " +props.transferIndex+ " için 'Transfer Saati' girilmemiş");
                rv.isok = false;
            }

            if (rv.isok && paxTransferDetail.value.flightNo==="") {
                libTools.method.swalError("Tamam", "Transfer " +props.transferIndex+ " için 'Uçuş No' girilmemiş");
                rv.isok = false;
            }

            if (rv.isok && paxTransferDetail.value.flyDepartureTime==="") {
                libTools.method.swalError("Tamam", "Transfer " +props.transferIndex+ " için 'Kalkış Saati' girilmemiş");
                rv.isok = false;
            }

            if (rv.isok && paxTransferDetail.value.flyArrivalTime==="") {
                libTools.method.swalError("Tamam", "Transfer " +props.transferIndex+ " için 'Varış Saati' girilmemiş");
                rv.isok = false;
            }

            if (rv.isok && !Number(paxTransferDetail.value.transferPayment)) {
                libTools.method.swalError("Tamam", "Transfer " +props.transferIndex+ " için 'Satış Fiyatı' girilmemiş");
                rv.isok = false;
            }

            if (rv.isok && !Number(paxTransferDetail.value.transferCost)) {
                libTools.method.swalError("Tamam", "Transfer " +props.transferIndex+ " için 'Maliyet Fiyatı' girilmemiş");
                rv.isok = false;
            }

            if (rv.isok && paxTransferDetail.value.VIPType==="") {
                libTools.method.swalError("Tamam", "Transfer " +props.transferIndex+ " için 'VIP Tipi' girilmemiş");
                rv.isok = false;
            }

            //PAX
            for (let i=0; i<paxTransferDetail.value.trsADTCount; i++) {
                //console.log("confirmValues paxTransferDetail.value.paxADTGender ["+ i +"]: "+ paxTransferDetail.value.paxADTGender[i]);
                if (rv.isok && paxTransferDetail.value.paxADTGender[i]===undefined) {
                    libTools.method.swalError("Tamam", "Transfer " +props.transferIndex+ " için " + (i+1) +". "+libTools.method.getPaxTypeName("ADT")+" için Cinsiyet seçilmemiş");
                    rv.isok = false;
                }
                if (rv.isok && paxTransferDetail.value.paxADTID[i]===undefined) {
                    libTools.method.swalError("Tamam", "Transfer " +props.transferIndex+ " için " + (i+1) +". "+libTools.method.getPaxTypeName("ADT")+" için Kimlik No girilmemiş");
                    rv.isok = false;
                }
                if (rv.isok && paxTransferDetail.value.paxADTName[i]===undefined) {
                    libTools.method.swalError("Tamam", "Transfer " +props.transferIndex+ " için " + (i+1) +". "+libTools.method.getPaxTypeName("ADT")+" için Ad girilmemiş");
                    rv.isok = false;
                }
                if (rv.isok && paxTransferDetail.value.paxADTSurname[i]===undefined) {
                    libTools.method.swalError("Tamam", "Transfer " +props.transferIndex+ " için " + (i+1) +". "+libTools.method.getPaxTypeName("ADT")+" için Soyad girilmemiş");
                    rv.isok = false;
                }
            }

            if (rv.isok) {
                rv.result = paxTransferDetail.value ;
                (rv.result as any).transferDate2 = dateConvert.method.convertToDateFormated((fpickTransferDate as any).selectedDates[0], "YYYY-MM-DD");
                (rv.result as any).transferIndex = props.transferIndex;
                (rv.result as any).transferPayment = paxTransferDetail.value.transferPayment;
                (rv.result as any).transferCost = paxTransferDetail.value.transferCost;
                (rv.result as any).transferLocationAName = libTools.method.getElSelectedText("selTransferLocationA_"+props.IdCode+"_"+props.transferIndex);
                (rv.result as any).transferLocationBName = libTools.method.getElSelectedText("selTransferLocationB_"+props.IdCode+"_"+props.transferIndex);
                (rv.result as any).RemarksJSON1 = genRemarksJSON1();
                (rv.result as any).RemarksJSON3 = genRemarksJSON3();
            }

            console.log("confirmValues END rv: "+JSON.stringify(rv));

            return rv;
        }

        function genRemarksJSON3() {
            let rv= {
                TransferCode: paxTransferDetail.value.transferCode,
                TransferType: paxTransferDetail.value.transferType,
                TransferLocationA: paxTransferDetail.value.transferLocationA,
                TransferLocationB: paxTransferDetail.value.transferLocationB,
                TransferLocationAName: libTools.method.getElSelectedText("selTransferLocationA_"+props.IdCode+"_"+props.transferIndex),
                TransferLocationBName: libTools.method.getElSelectedText("selTransferLocationB_"+props.IdCode+"_"+props.transferIndex),
                TransferTime: paxTransferDetail.value.transferTime,
                FlightDepTime: paxTransferDetail.value.flyDepartureTime,
                FlightArrTime: paxTransferDetail.value.flyArrivalTime,
                FlightNo: paxTransferDetail.value.flightNo,
                VIPType: paxTransferDetail.value.VIPType,
            };

            return rv;
        }

        function genRemarksJSON1() {
            let rv = [];
            for (let i=0; i<paxTransferDetail.value.trsADTCount; i++) {
                let objItem = {
                    PaxType : "ADT",
                    PaxGender : paxTransferDetail.value.paxADTGender[i],
                    PaxName : paxTransferDetail.value.paxADTName[i],
                    PaxSurname : paxTransferDetail.value.paxADTSurname[i],
                    PaxID : paxTransferDetail.value.paxADTID[i],
                    PaxBirthdate : paxTransferDetail.value.paxADTBDate[i],
                    PaxADTPhone : paxTransferDetail.value.paxADTPhone[i],
                    PaxADTEmail : paxTransferDetail.value.paxADTEmail[i],
                };
                rv.push(objItem);
            }
            for (let i=0; i<paxTransferDetail.value.trsCHDCount; i++) {
                let objItem = {
                    PaxType : "CHD",
                    PaxGender : paxTransferDetail.value.paxCHDGender[i],
                    PaxName : paxTransferDetail.value.paxCHDName[i],
                    PaxSurname : paxTransferDetail.value.paxCHDSurname[i],
                    PaxID : paxTransferDetail.value.paxCHDID[i],
                    PaxBirthdate : paxTransferDetail.value.paxCHDBDate[i],
                };
                rv.push(objItem);
            }
            for (let i=0; i<paxTransferDetail.value.trsCHDCount; i++) {
                  let objItem = {
                    PaxType : "INF",
                    PaxGender : paxTransferDetail.value.paxINFGender[i],
                    PaxName : paxTransferDetail.value.paxINFName[i],
                    PaxSurname : paxTransferDetail.value.paxINFSurname[i],
                    PaxID : paxTransferDetail.value.paxINFID[i],
                    PaxBirthdate : paxTransferDetail.value.paxINFBDate[i],
                  };
                  rv.push(objItem);
            }
            return rv;
        }

        return {
            dateConvert,
            Turkish,
            genderTypes,
            paxTransferDetail,
            dataTransfersList,
            dataTransferLocationsList,
            getTransferLocations,
            setContentInner,
            confirmValues,

        }
    },
});
