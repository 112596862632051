
import {computed, defineComponent, ref} from "vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import AppCore from "@/core/appcore/AppCore";
import {dateConvert} from "@/core/filters/datatime";
import {libTools} from "@/core/appcore/libs/Lib_Tools";


export default defineComponent({
    props: {
        remarkType: {type:String, required:true},
        remarkLabel: {type:String, required:true},
    },
    setup(props) {

        let theReservation = ref({});
        let ccvRemark = ref("");
        let arrRemarks = ref([]);

        function initComp(prmReservation) {
            theReservation.value = prmReservation.value;
        }

        const dataRemarkList = computed(() => {
            const dataComputed = store.getters.getRemarkList;
            setRemarks(dataComputed.data)
            return dataComputed;
        });

        function setRemarks(prmData) {
            arrRemarks.value = [];
            if (prmData!==undefined && prmData!==null) {
                for (let i=0; i<prmData.length; i++) {
                    let eachRemark = prmData[i];
                    if (eachRemark.SubType===props.remarkType) {
                        arrRemarks.value.push(eachRemark);
                    }
                }
            }
        }

        function click_Send_Remark() {

            console.log("click_Send_Remark getUserData: "+JSON.stringify(AppCore.prsCore_User.getUserInfo()));

            let vvUserData = AppCore.prsCore_User.getUserInfo();

            let vvPayload = {
                AccountID: 0,
                Username: vvUserData.Username,
                ObjectID: (theReservation.value as any).ID,
                Type: "ReservationDetail",
                SubType: props.remarkType,
                RemarkDate: dateConvert.method.convertToDateFormated(new Date(), "YYYY-MM-DD HH:mm:ss"),
                Remark: ccvRemark.value,
            };

            console.log("click_Send_Remark vvPayload: "+JSON.stringify(vvPayload));

            store.dispatch(Actions.REMARK_INSERT, vvPayload).then((prmData) => {
                console.log(prmData);

                if (prmData.data.status===201) {
                    store.dispatch(Actions.REMARK_LIST, {Type:"ReservationDetail", ObjectID: (theReservation.value as any).ID});
                    ccvRemark.value = "";

                    Swal.fire('Eklendi!', 'Kayit ekleme işlemi tamamlandı.', 'success').then((result)=>{
                        //
                    });
                } else {
                    libTools.method.swalError("Tamam", "Hata Oluştu");
                }
            }).catch(() => {
                libTools.method.swalError("Tamam", "Hata Oluştu");
            });
        }



        return {
            dateConvert,
            initComp,
            click_Send_Remark,
            dataRemarkList,
            theReservation,
            ccvRemark,
            arrRemarks,
        };
    },
});
