

import {computed, defineComponent, ref} from "vue";
import Currencies from "@/components/general-modals/cards/Currencies.vue";
import Transfer from "@/components/new-reservation-manuel/transfer/Transfer.vue";
import PaxTransferDetail from "@/components/new-reservation-manuel/transfer/PaxTransferDetail.vue";
import MasterValue from "@/components/new-reservation-manuel/general/MasterValue.vue";
import ContactInfo from "@/components/new-reservation-manuel/general/ContactInfo.vue";
import store from "@/store";
import AppCore from "@/core/appcore/AppCore";
import {Actions} from "@/store/enums/StoreEnums";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
    name: "reservation-transfer-add-modal",
    components: {
        Currencies,
        Transfer,
        PaxTransferDetail,
        MasterValue,
        ContactInfo,

    },

    props: {
        reservationid: {type:Number, required:true},
    },

    setup(props) {

        let refModal = ref<null | HTMLElement>(null);

        let refComp_MasterValues = ref(null);
        let refComp_Currencies = ref(null);
        let refComp_PaxTransferDetails = ref([]);

        const isSubmit = ref<string>("false");
        const selectedHotel = ref<any>();
        const ccvTransferCount = ref<number>(1);

        const myMasterValue = computed(() => {
            return store.getters.makeReservationsDetails;
        });

        function formSubmitNew() {
            isSubmit.value = "true";
        };

        function mySelectedHotel(myValue) {
            selectedHotel.value = myValue
        };

        function changedTransferCount(prmCount) {
            refComp_PaxTransferDetails.value= [];
            ccvTransferCount.value = prmCount;
        };

        const addRef_PaxTransferDetails = el => {
            if (el) {
                refComp_PaxTransferDetails.value.push(el);
                //console.log("addRef_PaxTransferDetails paxTransfer.len: "+(refComp_PaxTransferDetails.value as any).length);
            }
        }

        function click_Add() {
            console.log("click_Add START");
            processSend();
        }

        function processSend() {
            console.log("processSend START");
            let rv = {isok : true, result: {mastervalue:{}, currencies: {}, paxtransferdetails: [] }};

            /*
            //MasterValue
            if (rv.isok) {
                let resA = (refComp_MasterValues.value as any).getResults();
                if (resA.isok) {
                    rv.result.mastervalue = resA.result;
                } else {
                    rv.isok =false;
                }
            }
             */

            //Currencies
            if (rv.isok){
                let resCurrencies  = (refComp_Currencies.value as any).getResults();
                if (resCurrencies.isok) {
                    rv.result.currencies = resCurrencies.result;
                } else {
                    rv.isok = false;
                }
            }

            //PaxTransferDetail
            if (rv.isok){
                for (let i=0; i<ccvTransferCount.value; i++) {
                    if (rv.isok) {
                        let resA  = (refComp_PaxTransferDetails.value[i] as any).getResults();
                        if (resA.isok) {
                            rv.result.paxtransferdetails.push(resA.result);
                        } else {
                            rv.isok = false;
                        }
                    }
                }
            }

            console.log("processSend "+JSON.stringify(rv));

            //SUCCESS
            if (rv.isok) {
                console.log("click_Add SUCCESS DONE "+JSON.stringify(rv));
                AppCore.prsCore_Reservation.send_ReservationProduct_Manual(props.reservationid, "TRANSFER", rv.result);
            }

        }

        const dataReservationProductsResultInsertManual = computed(() => {
            let dataComputed =store.getters.getReservationProductsResultInsertManual;
            if (dataComputed!=undefined ) {
                checkResult(dataComputed);
            }
            return dataComputed;
        });

        function checkResult(prmData) {
            console.log(prmData);
            if (prmData.ProductType==="TRANSFER") {
                //libTools.method.swalSuccess("Tamam", "Transfer Bilgisi Eklendi");
                Swal.fire('Eklendi!', 'Transfer ekleme işlemi tamamlandı.', 'success').then((result)=>{
                    location.reload();
                });
                hideModal(refModal.value);
            }
        }

        return {
            formSubmitNew,
            ccvTransferCount,
            isSubmit,
            myMasterValue,
            changedTransferCount,
            mySelectedHotel,
            selectedHotel,
            refModal,
            refComp_MasterValues,
            refComp_Currencies,
            refComp_PaxTransferDetails, addRef_PaxTransferDetails,
            dataReservationProductsResultInsertManual,
            click_Add,
        }
    },


});
