
import {defineComponent, onMounted, ref} from "vue";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import {Turkish} from "flatpickr/dist/l10n/tr";
import flatpickr from "flatpickr";
import {dateConvert} from "@/core/filters/datatime";

export default defineComponent({
    name: "Pax-Detail",
    methods: {
        setContent(prmPaxInfo) {
            //console.log("PaxDetail setContent "+JSON.stringify(prmPaxInfo));
            this.setContentInner(prmPaxInfo);
        },
        getResults() {
            //console.log("PaxDetail getResults roomid: "+this.roomid+" paxType: "+this.paxType+" paxindex: "+this.paxindex);
            let rv = this.confirmValues();
            return rv;
        }
    },
    props: {
        idCode : {type:String, required: true},
        roomid: {type:Number, required: true},
        paxindex : {type:Number, required: true},
        paxType : {type:String, required: true},
    },
    setup(props) {
        var ccvPaxType = ref(props.paxType);


        onMounted (()=>{
            initDatePickers(null);
        });

        function initDatePickers(prmDefaultDate) {
            let cnfBirthDate = {
                "locale": Turkish,
                dateFormat: "d-m-Y (D)",
                maxDate: "today",
                defaultDate: prmDefaultDate!==null? dateConvert.method.dateToflatpicker(prmDefaultDate) : "",
            };
            //fpickBirthDate = flatpickr('#txt_RoomDetail_PaxDetail_Birthdate_'+props.idCode+'_'+props.roomid+'_'+props.paxType+'_'+props.paxindex, cnfBirthDate);
        }

        function setContentInner(prmPaxInfo) {
            console.log("PaxDetail setContent "+JSON.stringify(prmPaxInfo));
            libTools.method.setElValue("txt_RoomDetail_PaxDetail_Gender_"+props.idCode+"_"+props.roomid+"_"+props.paxType+"_"+props.paxindex, prmPaxInfo.Gender);
            libTools.method.setElValue("txt_RoomDetail_PaxDetail_IdNo_"+props.idCode+"_"+props.roomid+"_"+props.paxType+"_"+props.paxindex, prmPaxInfo.TcNo);
            libTools.method.setElValue("txt_RoomDetail_PaxDetail_Name_"+props.idCode+"_"+props.roomid+"_"+props.paxType+"_"+props.paxindex, prmPaxInfo.Name);
            libTools.method.setElValue("txt_RoomDetail_PaxDetail_Surname_"+props.idCode+"_"+props.roomid+"_"+props.paxType+"_"+props.paxindex, prmPaxInfo.Surname);
            libTools.method.setElValue("txt_RoomDetail_PaxDetail_Birthdate_"+props.idCode+"_"+props.roomid+"_"+props.paxType+"_"+props.paxindex, dateConvert.method.convertToDateFormated(prmPaxInfo.BirthDate, "DD-MM-YYYY") );

            //initDatePickers(prmPaxInfo.BirthDate);
        }

        function confirmValues() {

            let rv = {isok : true, result:{},};

            if (rv.isok && libTools.method.getElValue("txt_RoomDetail_PaxDetail_Gender_"+props.idCode+"_"+props.roomid+"_"+props.paxType+"_"+props.paxindex)==="") {
                libTools.method.swalError("Tamam", props.roomid + ". Odadaki "+props.paxindex+". "+libTools.method.getPaxTypeName(props.paxType)+" için Cinsiyet seçilmemiş");
                rv.isok = false;
            }

            if (rv.isok && libTools.method.getElValue("txt_RoomDetail_PaxDetail_IdNo_"+props.idCode+"_"+props.roomid+"_"+props.paxType+"_"+props.paxindex)==="") {
                libTools.method.swalError("Tamam", props.roomid + ". Odadaki "+props.paxindex+". "+libTools.method.getPaxTypeName(props.paxType)+" için Kimlik No girilmemiş");
                rv.isok = false;
            }

            if (rv.isok && libTools.method.getElValue("txt_RoomDetail_PaxDetail_Name_"+props.idCode+"_"+props.roomid+"_"+props.paxType+"_"+props.paxindex)==="") {
                libTools.method.swalError("Tamam", props.roomid + ". Odadaki "+props.paxindex+". "+libTools.method.getPaxTypeName(props.paxType)+" için Ad girilmemiş");
                rv.isok = false;
            }

            if (rv.isok && libTools.method.getElValue("txt_RoomDetail_PaxDetail_Surname_"+props.idCode+"_"+props.roomid+"_"+props.paxType+"_"+props.paxindex)==="") {
                libTools.method.swalError("Tamam", props.roomid + ". Odadaki "+props.paxindex+". "+libTools.method.getPaxTypeName(props.paxType)+" için Soyad girilmemiş");
                rv.isok = false;
            }
            if (rv.isok && libTools.method.getElValue("txt_RoomDetail_PaxDetail_Birthdate_"+props.idCode+"_"+props.roomid+"_"+props.paxType+"_"+props.paxindex)==="") {
                libTools.method.swalError("Tamam", props.roomid + ". Odadaki "+props.paxindex+". "+libTools.method.getPaxTypeName(props.paxType)+" için Doğum günü girilmemiş");
                rv.isok = false;
            }

            if (rv.isok) {
                rv.result = {
                    Type: props.paxType,
                    PaxIndex: props.paxindex,
                    Gender: libTools.method.getElValue("txt_RoomDetail_PaxDetail_Gender_"+props.idCode+"_"+props.roomid+"_"+props.paxType+"_"+props.paxindex),
                    TcNo: libTools.method.getElValue("txt_RoomDetail_PaxDetail_IdNo_"+props.idCode+"_"+props.roomid+"_"+props.paxType+"_"+props.paxindex),
                    Name: libTools.method.getElValue("txt_RoomDetail_PaxDetail_Name_"+props.idCode+"_"+props.roomid+"_"+props.paxType+"_"+props.paxindex),
                    Surname: libTools.method.getElValue("txt_RoomDetail_PaxDetail_Surname_"+props.idCode+"_"+props.roomid+"_"+props.paxType+"_"+props.paxindex),
                    BirthDate: dateConvert.method.flatpickerToDateYM(libTools.method.getElValue("txt_RoomDetail_PaxDetail_Birthdate_"+props.idCode+"_"+props.roomid+"_"+props.paxType+"_"+props.paxindex)),
                } ;
            }

            return rv;
        }

        return {
            libTools,
            ccvPaxType,
            setContentInner,
            confirmValues,
        };
    },
});
