
import {defineComponent, ref} from "vue";
import { Field} from "vee-validate";

interface transferValue {
    itemTransferCount: number;
}


export default defineComponent({
    name: "Transfer-Count",
    components: {
        Field,
    },

    props: {

    },


    setup(props, ctx) {


        function transferCountChange(event) {
            ctx.emit('changedTransferCount', event.target.value);
            console.log(event.target.value);
        }

        const transferValue = ref<transferValue>({
            itemTransferCount: 1,
        });


        return {
            transferValue,
            transferCountChange,
        }
    },
});
