

import {defineComponent, ref} from "vue";
import { dateConvert } from "@/core/filters/datatime";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import { jsPDF } from "jspdf";

import UploadFile from "@/components/appcomps/uploadfiles/UploadFile.vue";
import SendEmail from "@/components/appcomps/sendemail/SendEmail.vue";


export default defineComponent({
    name: "reservation-reservation-voucher-modal2",
    components: {
        UploadFile,
        SendEmail,
    },
    setup() {
        var theReservation = ref({});

        const refBtnExportToPdf = ref(null);
        const refBtnSendEmail = ref(null);
        const refFileUpload = ref(null);
        const refSendEmail = ref(null);

        var pWindow;

        function setValues(prmReservation) {
            theReservation.value = prmReservation.value;
            //console.log("setValues theRes: "+JSON.stringify(theReservation.value))
        }

        function printModal() {

            var divContents = document.getElementById("divReservation_Voucher_AllPrintable").innerHTML;
            pWindow = window.open('', '', '');
            pWindow.document.write('<html><body>');
            pWindow.document.write(divContents);
            pWindow.document.write('</body></html>');
            pWindow.document.close();

            setTimeout(()=>{pWindow.print();}, 500);
        }

        function getItems(prmReservationDetail, prmProductType) {
            let rv = [];
            if (prmReservationDetail.ITEMS!==undefined) {
                for (let i=0; i<prmReservationDetail.ITEMS.length; i++) {
                    let each = prmReservationDetail.ITEMS[i];
                    if (each.ProductType===prmProductType) {
                        rv.push(each);
                    }
                }
            }
            return rv;
        }

        function click_ExportToPdf(prmType, prmMailTo) {
            let elTarget = document.getElementById("divReservation_Voucher_AllPrintable");
            elTarget.innerHTML = libTools.method.convertTurkishToEnglish(elTarget.innerHTML);

            if (prmType==="Export") {
                refBtnExportToPdf.value.disabled = true;
                refBtnExportToPdf.value.setAttribute("data-kt-indicator", "on");
            } else {
                refBtnSendEmail.value.disabled = true;
                refBtnSendEmail.value.setAttribute("data-kt-indicator", "on");
            }


            setTimeout(()=>{ exportToPdf(prmType, prmMailTo); }, 500);
        }

        function click_SendEmail() {
            (refSendEmail.value as any).showComp("");
        }

        function emailEntered(prmEmail) {
            click_ExportToPdf("SendEmail", prmEmail);
        }

        function exportToPdf(prmType, prmMailTo) {
            const doc = new jsPDF('l', 'mm', [1150, 950]);
            let elTarget = document.getElementById("divReservation_Voucher_AllPrintable");

            doc.html(elTarget,
                {
                    callback: function(doc) {
                        let vvRes = theReservation.value as any;
                        let vvDirName = vvRes.VoucherNo.replaceAll(".", "-");
                        let vvKindName = 'reservations';
                        let vvFileName = "ReservationVoucher"+vvRes.VoucherNo.replaceAll(".", "_")+".pdf";
                        let vvMailTo = prmMailTo;
                        let vvContent = "Merhabalar, Rezervasyon Voucher ("+vvRes.ID+") bilgileri ektedir";
                        let vvToName = "Rezervasyon Voucher "+vvRes.ID;
                        let vvFromName = "Rezervasyon Voucher "+vvRes.ID;
                        let vvSubject = "Rezeervasyon Voucher "+vvRes.ID;

                        if (prmType==="Export") {
                            doc.save(vvFileName);
                            refBtnExportToPdf.value.disabled = false;
                            refBtnExportToPdf.value.setAttribute("data-kt-indicator", "off");
                        }
                        if (prmType==="SendEmail") {
                            var vvFile = doc.output('blob');
                            (refFileUpload.value).addFileAndSendEmail(vvFile, vvKindName, vvDirName, vvFileName, "HOTELVOUCHER", vvMailTo, vvToName, vvFromName, vvSubject, vvRes.AccountID, vvRes.ID, vvContent);


                        }

                    },
                    x: 10,
                    y: 4,
                    image: { type: "png", quality: 0.98 }, // as per req
                    html2canvas: { letterRendering: true, useCORS: true },
                }
            );

        }

        function onSuccessUploadFile() {
            refBtnSendEmail.value.disabled = false;
            refBtnSendEmail.value.setAttribute("data-kt-indicator", "off");
        }

        function onErrorUploadFile(prmErrorCode) {
            refBtnSendEmail.value.disabled = false;
            refBtnSendEmail.value.setAttribute("data-kt-indicator", "off");
        }

        return {
            theReservation,
            setValues,
            dateConvert, libTools,
            printModal,
            getItems,
            refBtnExportToPdf, click_ExportToPdf,
            refFileUpload, refBtnSendEmail, refSendEmail,
            onSuccessUploadFile, onErrorUploadFile,
            click_SendEmail, emailEntered,
        };
    },
});

